import PageBackgroundCheck from '../components/PageBackgroundCheck'
import { graphql } from 'gatsby'

export const query = graphql`
  query PageBackgroundCheck {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default PageBackgroundCheck
