import Modal from '../Modal'
import Template from '../Template'
import LockIcon from './lock.png'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import React, { useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { useDialogState, DialogDisclosure } from 'reakit/Dialog'
import {
  unstable_useFormState as useFormState,
  unstable_Form as Form,
  unstable_FormCheckbox as FormCheckbox,
  unstable_FormLabel as FormLabel,
  unstable_FormInput as FormInput,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form'

function TripleConfirm(props) {
  const tripleConfirm = useDialogState({ baseId: 'tripleConfirm' })
  const { onClick, ...form } = props

  return (
    <>
      <DialogDisclosure
        className={style.buttonSubmit}
        {...tripleConfirm}
        onClick={onClick}
      >
        Review background check details
      </DialogDisclosure>
      <Modal {...tripleConfirm}>
        <div className={style.modal}>
          <div>
            <div className={style.title}>
              <h2>Confirm background check details</h2>
              <button
                className={style.buttonClose}
                onClick={() => tripleConfirm.hide()}
              >
                Back
              </button>
            </div>
            <p>
              Please review your information and review our background check
              disclosures before submitting this form.
            </p>
            <div className={style.modalSummary}>
              <div className={style.summaryGroup}>
                <div className={style.label}>Drivers license number</div>
                <div className={style.value}>
                  {props.values.driversLicenseNumber}
                </div>
              </div>
              <div className={style.summaryGroup}>
                <div className={style.label}>Drivers license state</div>
                <div className={style.value}>
                  {props.values.driversLicenseState}
                </div>
              </div>
              <div className={style.summaryGroup}>
                <div className={style.label}>Drivers license city</div>
                <div className={style.value}>
                  {props.values.driversLicenseCity}
                </div>
              </div>
              <div className={style.summaryGroup}>
                <div className={style.label}>Date of birth</div>
                <div className={style.value}>{props.values.dob}</div>
              </div>
              <div className={style.summaryGroup}>
                <div className={style.label}>Social security number</div>
                <div className={style.value}>
                  {props.values.streetAddress} {props.values.ssn}
                </div>
              </div>
              <div className={style.disclosures}>
                <h4>Legal disclosures</h4>
                <ul>
                  <li>
                    <a
                      href='https://s3.doggos.com/legal-disclosure-documentation'
                      target='_blank'
                      rel='norefferer'
                    >
                      Background check disclosure
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://s3.doggos.com/legal-disclosure-documentation'
                      target='_blank'
                      rel='norefferer'
                    >
                      Additional disclosures
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://s3.doggos.com/legal-disclosure-documentation'
                      target='_blank'
                      rel='norefferer'
                    >
                      Authorization
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <Form {...form} className={style.buttonContainer}>
              <div className={style.backgroundCheckConsent}>
                <FormCheckbox name='backgroundCheckConsent' {...form} />
                <FormLabel htmlFor='backgroundCheckConsent'>
                  By clicking "Allow background check", I acknowledge that I
                  have read and agreed to the documents above. I authorize
                  doggos.com to order reports about me.
                </FormLabel>
              </div>
              <FormSubmitButton
                type='submit'
                className={style.buttonSubmit}
                disabled={!props.values.backgroundCheckConsent}
              >
                Allow background check
              </FormSubmitButton>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

function PageBackgroundCheck(props) {
  const [submitted, setSubmitted] = useState(false)
  const form = useFormState({
    values: {
      sitterFirstName: '',
      sitterLastName: '',
      businessDescription: '',
      driversLicenseState: '',
      driversLicenseCity: '',
      driversLicenseNumber: '',
      dob: '',
      ssn: '',
      backgroundCheckConsent: '',
    },
    onSubmit: async (values) => {
      const id = values.sitterFirstName.split(' ').join('-').toLowerCase()

      const formData = { ...values, id }

      document.body.scrollTop = 0
      setSubmitted(true)

      await fetch('/api/background-check', {
        method: 'POST',
        body: JSON.stringify(formData),
      })
    },
  })

  return (
    <Template>
      <Helmet>
        <title>
          Secure background check - {props.data.site.siteMetadata.title}
        </title>
      </Helmet>

      {/* The form */}
      {!submitted && (
        <>
          <div className={style.hero}>
            <h1>Complete your dog sitter application</h1>
            <p>
              A background check is the final step of the application process,
              and helps keep our community safe.
            </p>
          </div>
          <div className={style.formContainer}>
            <h2>
              <img className={style.lockIcon} src={LockIcon} /> Background check
            </h2>
            <p>
              Background checks are securely processed by third-party expert,{' '}
              <a
                href='https://checkr.com/background-check/criminal-background-checks'
                target='_blank'
              >
                Checkr.
              </a>{' '}
              After you have submit your background check, we will send you an
              email with your results. This process usually takes less than 5
              days.
            </p>
            <Form
              className={style.form}
              {...form}
              name='backgroundCheck'
              noValidate={false}
            >
              <div className={style.lockedForm}>
                <div>
                  <FormLabel htmlFor='sitterFirstName'>
                    Your legal first name
                  </FormLabel>
                  <FormInput
                    type='text'
                    name='sitterFirstName'
                    value='Yessenia'
                    disabled
                    {...form}
                  ></FormInput>
                </div>
                <div>
                  <FormLabel htmlFor='sitterLastName'>
                    Your legal last name
                  </FormLabel>
                  <FormInput
                    type='text'
                    name='sitterLastName'
                    value='Malgodova-Koporadraiyrza Smith'
                    disabled
                    {...form}
                  ></FormInput>
                </div>
              </div>
              <div className={style.lockedFormNote}>
                <strong>Note:</strong> The first name and last name you
                previously provided cannot be changed here, and must match your
                drivers license. If you need to update this information, please{' '}
                <a href='mailto:support@doggos.com'>email us</a> for help.
              </div>
              <div className={style.driversLicenseFileUpload}>
                <div>
                  <FormLabel required htmlFor='drivers-license'>
                    Valid US drivers license photo
                  </FormLabel>
                  <FormInput
                    type='file'
                    name='drivers-license'
                    accept='image/*'
                    {...form}
                    required
                  ></FormInput>
                </div>
              </div>
              <div className={style.driversLicense}>
                <div>
                  <FormLabel htmlFor='driversLicenseNumber'>
                    Drivers license number
                  </FormLabel>
                  <FormInput
                    type='number'
                    name='driversLicenseNumber'
                    minLength='8'
                    min='0'
                    inputMode='numeric'
                    {...form}
                  ></FormInput>
                </div>
                <div>
                  <FormLabel htmlFor='driversLicenseState'>
                    License state
                  </FormLabel>
                  <FormInput
                    type='text'
                    name='driversLicenseState'
                    minLength='2'
                    {...form}
                  ></FormInput>
                </div>
                <div>
                  <FormLabel htmlFor='driversLicenseCity'>
                    License city
                  </FormLabel>
                  <FormInput
                    type='text'
                    name='driversLicenseCity'
                    minLength='3'
                    placeholder='Las Vegas'
                    required
                    {...form}
                  ></FormInput>
                </div>
              </div>
              <div className={style.row}>
                <div>
                  <FormLabel htmlFor='dob'>Date of birth</FormLabel>
                  <FormInput type='date' name='dob' {...form}></FormInput>
                </div>
                <div>
                  <FormLabel htmlFor='ssn'>Social security number</FormLabel>
                  <FormInput
                    type='text'
                    name='ssn'
                    minLength='8'
                    placeholder='xxx-xx-xxx'
                    {...form}
                  ></FormInput>
                </div>
              </div>
              <div className={style.buttonContainer}>
                <TripleConfirm
                  {...form}
                  onClick={(event) => {
                    if (
                      !window.document.forms.backgroundCheck.reportValidity()
                    ) {
                      event.preventDefault()
                    }
                  }}
                />
              </div>
            </Form>
          </div>
        </>
      )}

      {/* Thank you! message after form submit */}
      {submitted && (
        <div className={style.fadeIn}>
          <div className={style.hero}>
            <h1>Thank you!</h1>
            <p>Your background check is being processed.</p>
          </div>
          <div className={style.resultBox}>
            <h3>What happens next?</h3>
            <p>
              We'll reach out to you with the results of your background check,
              usually in 3-5 days or less. If your background check is approved,
              you will receive an email with a link to login your account.
            </p>
            <p>
              If your background check fails to process or is denied, you will
              get an email from us, and from Checkr with more details.
            </p>
            <h4>How long do background checks take?</h4>
            <p>
              Background checks usually take less than 3-5 days, with some
              processing as quickly as 30 minutes.
            </p>
            <h4>Helpful resources</h4>
            <p>
              While you wait for your application to be processed, check out
              these helpful resources for being successful on doggos.com:
            </p>
            <ul>
              <li>
                <Link to={'/getting-started-as-a-dog-sitter'}>
                  Getting started as a dog sitter on doggos.com
                </Link>
              </li>
              <li>
                <Link to={'/how-do-payments-work-for-dog-sitters'}>
                  How do payments work?
                </Link>
              </li>
              <li>
                {' '}
                <Link to={'/how-do-refunds-work-for-dog-sitters'}>
                  How do refunds work?
                </Link>
              </li>
              <li>
                <Link to={'/editing-your-dog-sitter-profile'}>
                  Editing your profile
                </Link>
              </li>
            </ul>
            <p>
              Thank you for taking the time to apply! We look forward to having
              you as a user and a member of our community.
            </p>
          </div>
        </div>
      )}
    </Template>
  )
}

export default PageBackgroundCheck
